.swiper-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiper {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .custom-prev-btn, .custom-next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #333;
    color: white;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
    height: 26px;
    width: 26px;
    background: #0000005E;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .custom-prev-btn {
    left: -50px;
  }
  
  .custom-next-btn {
    right: -50px;
  }
  

  