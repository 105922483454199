
.filter-icon-group {
    stroke: none;
    strokeWidth: 0;
    stroke-dasharray: none;
    strokeLinecap: butt;
    strokeLinejoin: miter;
    stroke-miterlimit: 10;
    fill: none;
    fillRule: nonzero;
    opacity: 1;
  }
  
  .filter-icon-path {
    stroke: none;
    strokeWidth: 1;
    stroke-dasharray: none;
    strokeLinecap: butt;
    strokeLinejoin: miter;
    stroke-miterlimit: 10;
    fill: rgb(0, 0, 0);
    fillRule: nonzero;
    opacity: 1;
  }
  