@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Geist";
  src: url("./assets/fonts/GeistVF.ttf");
}

@font-face {
  font-family: "Sora";
  src: url("./assets/fonts/Sora-VariableFont_wght.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Geist", sans-serif;
}

body {
  margin: 0;
  background-color: #f6f6f6 !important;
  color: #000000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Geist", serif;
}

.ps-menu-button.ps-active {
  border-right: 2px solid;
}

.ps-menu-button {
  gap: 15px;
}

.ps-menu-button:hover {
  border-right: 2px solid;
  background-color: unset;
}

.ps-menu-label {
  font-family: "Geist";
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.2em;
  text-align: left;
}

.ps-menu-label.ps-active,
.ps-menu-label:hover {
  font-weight: 700;
}

.ps-menu-icon {
  margin-right: 0 !important;
  width: 45px !important;
  height: 45px !important;
}

.ps-active.ps-menu-icon {
  background-color: black;
}

.ps-active.ps-menu-icon > svg > path {
  fill: white;
}

.ps-menu-button:hover > .ps-menu-icon {
  background-color: black;
}

.ps-menu-button:hover > .ps-menu-icon > svg > path {
  fill: white;
}

.active-link {
  border-bottom: 2px solid black;
  padding: 0px 10px;
  font-weight: bold;

  transition: 0.3s ease-in-out;
  color: #000000;
}

.active {
  font-size: 13px;
  font-weight: bolder;
  transition: 0.3s ease-in-out;
}

input {
  border-radius: 0px;
}

.my-masonry-grid {
  display: flex;
  margin-left: 0px;
  padding-right: 12px;
  /* padding-right: 16px; */
  display: -webkit-box;
  display: -ms-flexbox;
  width: auto;

  @media screen and (max-width: 425px) {
    padding-right: 6px;
  }
}

.my-masonry-grid_column {
  width: 200px;
  padding-left: 16px;
  background-clip: padding-box;

  @media screen and (max-width: 450px) {
    width: 180px;
    padding-left: 12px;
  }

  @media screen and (max-width: 425px) {
    width: 180px;
    padding-left: 10px;
  }
}

.my-masonry-grid_column > div {
}

.center-element-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
